<template>
  <div class="breadcrumb-new">
    <Breadcrumb>
      <label class="bread-head"><i class="iconfont icon-ico_site icon-address"></i> 您所在位置: </label>
      <template v-for="(item, index) in routeArr">
        <BreadcrumbItem :key="index">
          {{item.name}}
        </BreadcrumbItem>
      </template>
    </Breadcrumb>
  </div>
</template>

<script>
export default {
  name: "index",
  computed: {
   routeArr () {
     let route = this.$route
     let result = []
     if (route.meta && route.meta.noShowingChildren) {
       let temp = Object.assign({}, route.matched[0], {
         path: route.fullPath
       })
       result = [temp]
     } else {
       result = route.matched
     }
     return result
   }
  }
}
</script>

<style scoped lang="less">
  @import "index";
</style>
