import defaultSettings from '@/config'
const state = {
    theme: defaultSettings.theme,
    sideTheme: defaultSettings.sideTheme,
    sidebarLogo: defaultSettings.sidebarLogo
}
const mutations = {
    CHANGE_SETTING: (state, {key, value}) => {
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    }
}
const actions = {
    changeSetting ({ commit }, data) {
        commit('CHANGE_SETTING', data)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
