<template>
  <div class="main-box">
    <div class="main">
      <div class="top-wrapper" ref="top">
        <sidebar-menu :height="height" v-if="sidebarRoutes.length"></sidebar-menu>
      </div>
      <div class="center" :style="{width: width + 'px'}">
        <div class="center-top">
          <div class="header-left">
            <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
            <breadcrumb-new v-if="!$route.meta.noBreadcrumb"></breadcrumb-new>
          </div>
          <div class="header-wrapper">
            <Poptip trigger="hover">
              <a href="javascript:;" class="username">{{ user.userName }}<Icon type="md-arrow-dropdown" /></a>
              <ul slot="content" class="header-user">
                <li @click="toMod">修改密码</li>
              </ul>
            </Poptip>
            <span class="icon" @click="toMessage"><Icon type="md-mail" /></span>
            <span class="icon" @click="toLogout"><Icon type="md-exit" /></span>
          </div>
        </div>
        <div class="content" :style="{height: height + 'px'}">
          <div class="router-content" :style="routerStyle">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <div class="main-bottom">
      <p>Copyright © 2011-2023 <a :href="globalConfig.companyUrl" target="_blank">{{ globalConfig.companyUrl }}</a> 版权所有:
        {{ globalConfig.company }} 公网备案
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ globalConfig.icpNum }}</a>
      </p>
    </div>
    <Modal v-model="showPwd" title="修改密码" width="400" :mask-closable="false" :before-close="pwdBeforeClose">
      <Form :model="form" class="form-password" :rules="pwdFormRule" ref="pwdForm">
        <FormItem label="原密码: " prop="userAdminPwd" required>
          <Input type="password" style="width: 0;opacity: 0;" />
          <Input type="password" v-model="form.userAdminPwd" placeholder="请输入原密码"/>
        </FormItem>
        <FormItem label="新密码: " prop="userAdminPwdNew" required>
          <Input type="password" v-model="form.userAdminPwdNew" placeholder="请输入新密码" />
        </FormItem>
        <FormItem label="确认密码: " prop="repeatPwd" required>
          <Input type="password" v-model="form.repeatPwd" placeholder="请输入确认密码" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button class="ivu-btn-text" @click="pwdCancel">取消</Button>
        <Button class="ivu-btn-primary" @click="pwdOk">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import SidebarMenu from '@/components/Sidebar/index.vue'
import dayjs from 'dayjs'
import { removeStorageAll, getStorage, setStorage } from '@/libs/tools'
import { mapState, mapGetters } from 'vuex'
import { getTableHeight } from '@/libs/util'
import BreadcrumbNew from '@/components/BreadcrumbNew'
import Hamburger from '@/components/Hamburger'
import { updatePwd, AMapWebServiceKey, getBaseReqUrlNoPage } from "@/libs/api"
import axios from 'axios'
import globalConfig from '@/config'

export default {
  name: 'Main',
  components: {
    SidebarMenu,
    BreadcrumbNew,
    Hamburger
  },
  data () {
    return {
      globalConfig,
      user: getStorage('user') ? JSON.parse(getStorage('user')) : {},
      height: 0,
      timer: null,
      width: 0,
      showPwd: false,
      form: {
        userAdminPwd: '',
        userAdminPwdNew: '',
        repeatPwd: ''
      },
      pwdFormRule: {
        userAdminPwd: [{required: true, message: '请输入原密码!'}],
        userAdminPwdNew: [{required: true, message: '请输入新密码!'}],
        repeatPwd: [
          {required: true, message: '请输入确认密码!'},
          { validator: (rule, value, callback) => {
              if (value !== this.form.userAdminPwdNew) {
                callback(new Error('两次密码输入不一致!'))
              } else {
                callback()
              }
            }}
        ]
      },
      routerStyle: {}
    }
  },
  computed: {
    ...mapState(['currentTime']),
    ...mapGetters([
      'sidebar',
      'avatar',
      'device',
      'sidebarRoutes'
    ])
  },
  mounted () {
    this.$nextTick(() => {
      this.routerStyle = ['/home'].includes(this.$route.path) ? {
        backgroundColor: '#f1f1f1',
        padding: 0
      } : {}
      this.$nextTick(() => {
        this.setRightHeight()
        this.setTableHeight()
      })
      window.onresize = () => {
        this.setRightHeight()
        this.setTableHeight()
        let chartPage = this.findCharts(this, [])
        if (chartPage.length) {
          setTimeout(() => {
            Object.keys(chartPage[0]).forEach((key) => {
              if (key.toUpperCase().indexOf('CHARTSOBJ') > -1) {
                chartPage[0][key].resize()
              }
            })
          }, 100)
        }
      }
      this.timer = setInterval(() => {
        this.$store.dispatch('setTime', dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'))
      }, 1000)

      this.getDistrictData()
      this.getBaseReqUrl()
    })
  },
  methods: {
    toLogout () {
      this.$Modal.confirm({
        title: '退出',
        content: '确认退出系统？',
        onOk: () => {
          this.$store.dispatch('LogOut').then(() => {
            this.$router.push('/website')
          }).catch((err) => {
            this.$Message.error(err)
          })
        }
      })
    },
    setRightHeight () {
      let bottomHeight = document.querySelector('.main-bottom').offsetHeight
      this.height = document.documentElement.clientHeight - 65 - bottomHeight
      this.width = document.querySelector('.main').offsetWidth - document.querySelector('.top-wrapper').offsetWidth
    },
    setTableHeight () {
      let child = this.$children
      if (child.length) {
        child.forEach((item) => {
          if (item.$refs.table || (item.$children[0] && item.$children[0].$refs.table)) { // 表格高度
            getTableHeight()
          }
        })
      }
    },
    toMod () {
      this.showPwd = true
    },
    toMessage () {

    },
    findCharts (ele, arr) { // 查询页面是否有charts
      if (ele.$refs.charts) {
        arr.push(ele)
        return false
      }
      if (ele.$children.length) {
        ele.$children.forEach((item) => {
          this.findCharts(item,arr)
        })
      }
      return arr
    },
    getDistrictData () { // 行政区域
      let regionData = JSON.parse(getStorage('regionData'))
      if (!(regionData && regionData.length)) {
        axios({
          url: 'https://restapi.amap.com/v3/config/district',
          params: {
            key: AMapWebServiceKey,
            subdistrict: 3
          }
        }).then((res) => {
          let data = res.data
          if (data.status === '1') {
           let provinceArr = data.districts[0].districts
            provinceArr.forEach((pro) => {
              pro.label = pro.name
              pro.value = pro.adcode
              pro.children = pro.districts
              if (pro.districts.length) {
                pro.districts.forEach((city) => {
                  city.label = city.name
                  city.value = city.adcode
                  city.children = city.districts
                  if (city.districts.length) {
                    city.districts.forEach((county) => {
                      county.label = county.name
                      county.value = county.adcode
                      county.children = county.districts
                    })
                  }
                })
              }
            })
            setStorage('regionData', JSON.stringify(provinceArr))
          }
        })
      }
    },
    pwdBeforeClose () {
      return false
    },
    pwdOk () {
      this.$refs.pwdForm.validate().then(async (valid) => {
        if (valid) {
          await updatePwd({
            userAdminPwd: this.form.userAdminPwd,
            userAdminPwdNew: this.form.userAdminPwdNew,
            userWxOpenId: this.user.userWxOpenId
          }).then((res) => {
            if (res.data.code === 0) {
              this.$Message.success('操作成功！')
              this.showPwd = false
              this.$store.dispatch('removeUser')
              removeStorageAll()
              this.$router.push('/login')
            } else {
              this.$Message.error(res.data.message)
            }
          })
        }
      })
    },
    pwdCancel () {
      this.showPwd = false
    },
    getBaseReqUrl () {
      getBaseReqUrlNoPage({}).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data
          setStorage('urls', JSON.stringify(data))
        } else {
          this.$Message.error(res.data.message)
        }
      })
    },
    toggleSideBar() {
      this.$store.dispatch('toggleSidebar')
    },
  },
  watch: {
    '$route': {
      handler (to) {
        this.$nextTick(() => {
          this.setTableHeight()
          this.routerStyle = ['/home'].includes(to.path) ? {
            backgroundColor: '#f1f1f1',
            padding: 0
          } : {}
        })
      }
    },
    showPwd (val) {
      if (!val) {
        this.form = {
          userAdminPwd: '',
          userAdminPwdNew: '',
          repeatPwd: ''
        }
        this.$refs.pwdForm.resetFields()
      }
    },
    'sidebar.opened': function(){
      this.setRightHeight()
    }
  }
}
</script>
<style lang="less">
.top{
  display: flex;
  flex-wrap: wrap;
}
.center-top{
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0 0 3px rgba(66, 169, 243, .1);
  padding: 13px @base * 2 0 @base * 2;
  height: 60px;
  box-sizing: border-box;
  justify-content: space-between;
  .header-left{
    display: flex;
    align-items: center;
  }
  .right-name{
    font-size: 20px;
    margin-right: @base * 2;
  }
  .header-wrapper{
    display: flex;
    .icon{
      font-size: 22px;
      padding: 0 @base * 2;
      color: @baseColor;
      cursor: pointer;
      position: relative;
      &:before{
        content: '';
        width: 1px;
        background: #dfdfdf;
        height: 60%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .ivu-poptip{
      margin-right: @base * 2;
    }
    .header-user{
      padding:0 @base;
      li{
        text-align: center;
        padding: @base * 2 0;
        cursor: pointer;
        border-bottom: 1px solid #dfdfdf;
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
}
.ivu-form.form-password{
  padding: 0 @base * 4;
    .ivu-form-item{
      width: 100%;
    }
}
.main-bottom{
  width: 100%;
  padding-top: @base;
  text-align: center;
}
</style>
