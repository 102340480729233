import { getStorage, setStorage } from '@/libs/tools'
const app = {
    state: {
        sidebar: {
            opened: getStorage('sidebarStatus') ? !!+ getStorage('sidebarStatus') : false,
            openNames: ['/system', '/order'],
            withoutAnimation: false
        },
        device: 'desktop',
        size: getStorage('size') || 'medium'
    },
    mutations: {
        TOGGLE_SIDEBAR: (state) => {
            state.sidebar.opened = !state.sidebar.opened
            state.withoutAnimation = false
            setStorage('sidebarStatus', state.sidebar.opened)
        },
        CLOSER_SIDEBAR: (state, withoutAnimation) => {
            setStorage('sidebarStatus', false)
            state.sidebar.opened = false
            state.sidebar.withoutAnimation = withoutAnimation
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device
        },
        SET_SIZE: (state, size) => {
            state.size = size
            setStorage('size', size)
        }
    },
    actions: {
        toggleSidebar ({ commit }) {
            commit('TOGGLE_SIDEBAR')
        },
        closeSidebar ({ commit }, { withoutAnimation }) {
            commit('CLOSER_SIDEBAR', withoutAnimation)
        },
        toggleDevice ({ commit }, device) {
            commit('TOGGLE_DEVICE', device)
        },
        setSize ({ commit}, size) {
            commit('SET_SIZE', size)
        }
    }
}
export default app
