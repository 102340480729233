import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
Vue.use(VueRouter)

export const constantRoutes = [
    {
        path: '/redirect',
        component: Main,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: (resolve) => require(['@/views/redirect'], resolve)
            }
        ]
    },
    {
        path: '/login',
        hidden: true,
        component: (resolve) => require(['@/views/login/login'], resolve)
    },
    {
        path: '/appshow',
        hidden: true,
        component: (resolve) => require(['@/views/appshow/appshow'], resolve)
    },
    {
        path: '/service',
        name: 'service',
        hidden: true,
        component: (resolve) => require(['@/views/service/service'], resolve)
    },
    {
        path: '/privacy',
        name: 'privacy',
        hidden: true,
        component: (resolve) => require(['@/views/privacy/privacy'], resolve)
    },
    {
        path: '/big-screen',
        name: 'big-screen',
        hidden: true,
        component: (resolve) => require(['@/views/big-screen/big-screen'], resolve)
    },
    {
        path: '/website',
        name: 'website',
        hidden: true,
        component: (resolve) => require(['@/views/website-page/website-main'], resolve),
    },
    {
        path: '/',
        component: Main,
        redirect: '/home',
        hidden: false,
        name: '首页',
        noShowingChildren: true,
        children: [
            {
                path: '/home',
                name: '首页-1',
                component: (resolve) => require(['@/views/home/home'], resolve),
                meta: {
                    name: '首页-1',
                    icon: 'icon-caidan',
                    keepAlive: false,
                    noShowingChildren: true
                }
            }
        ]
    }
]
export default new VueRouter({
    mode: 'hash',
    routes: constantRoutes
})
