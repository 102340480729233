const getters = {
    sidebar: state => state.app.sidebar,
    size: state => state.app.size,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    messageCount: state => state.user.messageCount,
    messageList: state => state.user.messageList,
    introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    permission_routes: state => state.permission.routes,
    topbarRoutes:state => state.permission.topbarRoutes,
    defaultRoutes:state => state.permission.defaultRoutes,
    sidebarRoutes:state => state.permission.sidebarRoutes,
}
export default getters
