/**
 * 封装axios
 */
import axios from 'axios'
import app from '@/main'
import { getStorage, removeStorageAll } from './tools'
const addErrorLog = (errInfo, options) => {
  if (errInfo) {
    const { statusText, status, request: { responseURL }} = errInfo
    let msg = ''
    switch (status) {
      case 400: msg = '请求信息有误';break
      case 404: msg = '数据不存在'; break
      case 405: msg = '错误的请求类型'; break
      case 500: msg = '服务器开小差了，请稍后再试'; break
      case 501: {
        console.log(`接口${responseURL}还未实现`)
        break
      }
      case 503: msg = '系统维护，请稍后再试'; break
      default: {
        msg = statusText === 'Network Error' ? '网络异常，请检查网络稍后再试' : '数据处理错误'
      }
    }
   !options.noToast && app.$Message.warning({
      content: msg,
      duration: 2,
      top: 100
    })
  }
}
const responseErr = (data) => {
  let code = data.code || data.Code || data.Ret
  if ([1001, 4003, 401, 403].includes(code)) { // 1001/401 token失效  4003时间戳过期
    let tokenFlag = app.$store.state.tokenFlag
    if (tokenFlag === 0) {
      app.$Message.warning('登录失效，请重新登录！')
    }
    removeStorageAll()
    app.$store.dispatch('removeUser')
    app.$store.dispatch('setTokenFlag', 1)
    let currentRoute = app.$router.history.current
    if (currentRoute.name !== 'login') {
      app.$router.replace({
        path: '/login',
        query: {
          redirect: currentRoute.fullPath
        }
      })
    } else {
      app.$router.push('/login')
    }
  }
}
class HttpRequest {
  constructor (baseUrl) {
    this.baseUrl = baseUrl
    this.queue= {}
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      timeout: 30000
    }
    return config
  }
  destory (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      //
    }
  }
  interceptors (instance, url, options) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      this.queue[url] = true
      return config
    }, err => {
      return Promise.reject(err)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destory(url)
      const { data, status } = res
      responseErr(res.data)
      return Promise.resolve({
        data,
        status
      })
    }, err => {
      let info = err.response
      if (info) {
       if (info.status === 200) {
         const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(err))
         info = {
           statusText,
           status,
           request: { responseURL: config.url}
         }
         addErrorLog(info, options)
       } else if (info.status === 401) {
         responseErr(info.data)
       }
      } else {
       !options.noToast && app.$Message.warning({
          content: '网络异常，请检查网络稍后再试',
          duration: 2,
          top: 100
        })
      }
      return Promise.reject(err)
    })
  }
  request (options) {
    let headersCfg = {}
    if (!options.noToken) {
      let token = getStorage('token') ? getStorage('token') : ''
      headersCfg = {
        headers: {
          Authorization: 'Bearer ' + token,
          SYSTOKEN: token
        }
      }
    }
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), headersCfg, options)
    let isCompleteUrl = /https?:\/\/([\w.]+\/?)\S*/.test(options.url)
    if (isCompleteUrl) {
      options.baseUrl = ''
    }
    options.method = options.method || 'POST'
    this.interceptors(instance, options.url, options)
    return instance(options)
  }
}
export default HttpRequest
