<template>
  <div :class="{'has-logo': showLogo}" :style="{backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg, height: height + 'px'}" class="ivu-menu-wrapper">
    <logo v-if="showLogo" :collapse="isCollapse"></logo>
    <Scroll class="scrollbar-wrapper" :class="settings.sideTheme" :height="height">
      <Menu :theme="settings.sideTheme === 'theme-dark' ? 'dark' : 'light'"
            :active-name="activeMenu"
            :open-names="sidebar.openNames"
            :mode="mode"
            @on-select="routeChange"
            :width="!isCollapse ? '240px' : '80px'">
        <sidebar-item
            v-for="(route, index) in sidebarRoutes"
            :key="route.path  + index"
            :item="route"
            :base-path="route.path"/>
      </Menu>
    </Scroll>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/config'
export default {
  components: {
    Logo,
    SidebarItem
  },
  props: {
    height: {
      type: [Number, String],
      default: 400
    },
    mode: {
      type: String,
      default: 'vertical'
    },
    openNames: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['sidebarRoutes', 'sidebar']),
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu){
        return meta.activeMenu
      }
      return path
    },
    showLogo () {
      return this.$store.state.settings.sidebarLogo
    },
    variables () {
      return variables
    },
    isCollapse () {
      return this.sidebar.opened
    }
  },
  mounted () {
    this.$nextTick(() => {
      // console.log(this.sidebarRoutes)
    })
  },
  methods: {
    routeChange (name) {
      // console.log(name)
    }
  }
}
</script>

<style scoped lang="less">
@import "sidebar";
.ivu-menu-wrapper{
  /deep/.ivu-scroll-container{
    overflow-y: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar { width: 0 !important }
  }
  .ivu-menu-dark{
    background: none;
     /deep/.ivu-menu-opened{
      background: none;
    }
    /deep/.ivu-menu-submenu-title{
      background: none !important;
    }
    /deep/.ivu-menu-child-item-active>.ivu-menu-submenu-title{
      color: #87cefa !important;
    }
    /deep/.ivu-menu-submenu .ivu-menu-item-active,
    /deep/.ivu-menu-submenu .ivu-menu-item-active:hover{
      background: rgba(0,0,0,.25) !important;
    }
  }
}
</style>
<style lang="less">
  .ivu-menu-dark.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu),
  .ivu-menu-dark.ivu-menu-vertical .ivu-menu-submenu-title-active:not(.ivu-menu-submenu){
    color: #fff !important;
    background: rgba(0,0,0,.25) !important;
  }
</style>
