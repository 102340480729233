import router from './router/index-new'
import store from './store'
import viewUI from 'view-design'
import Router from 'vue-router'
import { getStorage, deepCopy } from '@/libs/tools'
import globalConfig from '@/config'
router.beforeEach((to, from, next) => {
    viewUI.LoadingBar.start()
    const token = getStorage('token')
    if (globalConfig.whiteList.indexOf(to.path) > -1) {
        next()
    } else {
        if (token) {
            if (to.path === '/login') {
                let user = JSON.parse(getStorage('user'))
                if (user && user.userType && user.userType === -1) {
                    next({path: '/big-screen'})
                } else {
                    next({path: '/home'})
                }
                viewUI.LoadingBar.finish()
            } else {
                let user = JSON.parse(getStorage('user'))
                if (user && user.userType && user.userType === -1) {
                    if (to.path === '/home') {
                        next({path: '/big-screen'})
                    }
                }
                if (store.getters.roles.length === 0) {
                    store.dispatch('GetInfo').then(() => {
                        store.dispatch('GenerateRoutes').then( accessRoutes => {
                            let originalRoute = deepCopy(router.options.routes)
                            let allRoutes = originalRoute.concat(accessRoutes)
                            router.matcher = new Router().matcher
                            router.options.routes = allRoutes
                            router.addRoutes(allRoutes)
                            next(({...to, replace: true}))
                        })
                    }).catch(err => {
                        store.dispatch('LogOut').then(() => {
                            next({ path: '/website' })
                        })
                    })
                } else {
                    next()
                }
            }
        } else {
            let fPath = to.fullpath
            let url = fPath ? `/website?redirect=${fPath}` : '/website'
            next(url)
            viewUI.LoadingBar.finish()
        }
    }
})
router.afterEach(() => {
    viewUI.LoadingBar.finish()
})
