import Vue from 'vue'
import Vuex from 'vuex'
import { setStorage, removeStorage } from '../libs/tools'
import dayjs from 'dayjs'
import permission from './modules/permission'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import getters from './getters'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    currentTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    tableHeight: 0, // 表格高度
    tokenFlag: 0, // 控制token失效时弹窗显示的次数
    menuActiveName: 'home'
  },
  mutations: {
    SET_TIME (state, time) {
      state.currentTime = time
    },
    SET_TABLE_HEIGHT (state, height) {
      state.tableHeight = height
    },
    SET_TOKEN_FLAG (state, flag) {
      state.tokenFlag = flag
    },
    SET_MENU_NAME (state, name) {
      state.menuActiveName = name
    }
  },
  actions: {
    setTime: ({ commit }, payload) => {
      commit('SET_TIME', payload)
    },
    setTableHeight: ({ commit }, payload) => {
      commit('SET_TABLE_HEIGHT', payload)
    },
    setTokenFlag: ({ commit }, payload) => {
      commit('SET_TOKEN_FLAG', payload)
    },
    setMenuName: ({ commit }, payload) => {
      commit('SET_MENU_NAME', payload)
    }
  },
  modules: {
    permission,
    app,
    settings,
    user
  },
  getters
})
