/**
 * 公用工具方法 by zj
 */
import config from '@/config'
export const getStorage = (key) => {
  return window.localStorage.getItem(key)
}
export const setStorage = (key, val) => {
  window.localStorage.setItem(key, val)
}
export const removeStorage = (key) => {
  window.localStorage.removeItem(key)
}
export const removeStorageAll = () => {
  removeStorage('user')
  removeStorage('deptList')
  removeStorage('userList')
  removeStorage('token')
  removeStorage('regionData')
}
// 动态引入js
export const injectScript = (src) => {
  const s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = src
  const t = document.getElementsByTagName('script')[0]
  t.parentNode.insertBefore(s, t)
}

// 去重
export const unique = (arr) => {
  if (!Array.isArray(arr) || !arr.length) return false
  let n = {}
  let r = []
  for (let i = 0; i < arr.length; i++) {
    if (!n[arr[i]]) {
      n[arr[i]] = true
      r.push(arr[i])
    }
  }
  return r
}

// 16进制颜色转RGB,RGBA字符串
export const colorToRgb = (color, opa) => {
  let str = ''
  let pattern = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let isOpa = typeof opa === 'number'
  if (!pattern.test(color)) {
    str = color
  } else {
    let cls = color.replace(/#/, '')
    let rgbArr = []
    for (let i = 0; i < 3; i++) {
      let it = cls.substring(i * 2, i * 2 + 2)
      let num = parseInt(it, 16)
      rgbArr.push(num)
    }
    str = rgbArr.join()
    str = 'rgb' + (isOpa ? 'a' : '') + '(' + str + (isOpa ? ',' + opa : '')+ ')'
  }
  return str
}

// 导出
export const exportExcel = (name, res) => {
  let blob = new Blob([res], {type: 'application/vnd.ms-excel;charset=UTF-8'})
  const filename = name + '.xlsx'
  if ('msSaveOrOpenBlob' in navigator) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    let alink = document.createElement('a')
    alink.download = filename
    alink.style.display = 'none'
    alink.href = window.URL.createObjectURL(blob)
    document.body.appendChild(alink)
    alink.click()
    window.URL.revokeObjectURL(alink.href)
    document.body.removeChild(alink)
  }
}

// 获取下拉框value
export const getSelectLabel = (data, value) => {
  let val = '' || []
  if (data && data.length) {
    data.forEach((item) => {
      if (Array.isArray(value)) {
        if (value.length) {
          value.forEach((v) => {
            if (v === item.value) {
              val.push(item.label)
            }
          })
        }
      } else {
        if (item.value === value) {
          val = item.label
          return false
        }
      }
    })
  }
  return val
}

/**
 * 解析时段电价 (合并相邻时段)
 * @param {*} numberObj 费率号集合(48段)
 */
export function resolvePriceRange (numberObj) {
  let cateObjArr = []
  if (!numberObj) {
    console.error('参数异常')
    return false
  }
  let cateAllArr = []
  for (let i = 0; i < numberObj.length; i+=2) {
    let str = numberObj[i] + '' + numberObj[i + 1]
    cateAllArr.push(str)
  }
  let index = 0
  for (let i = 0; i < cateAllArr.length; i++) {
    let lastStr =  cateAllArr[i - 1]
    let prevStr = cateAllArr[i]
    let nextStr = cateAllArr[i + 1]
    let obj = {
      start: index,
      value: prevStr
    }
    if (nextStr) {
      if (nextStr === prevStr) {
        if (i + 1 === cateAllArr.length - 1) {
          obj.end = i + 1
          cateObjArr.push(obj)
        }
      } else {
        obj.end = i
        cateObjArr.push(obj)
        index = i + 1
      }
    } else {
      if (prevStr !== lastStr){
        obj.start = i
        obj.end = i
        cateObjArr.push(obj)
      }
    }
  }
  return  cateObjArr
}

// 清空表单
export const clearForm = (ref, obj) => {
  ref.resetFields()
  Object.keys(obj).forEach(key => {
    obj[key] = ''
  })
}

// 按钮权限
export const isPermission = (btn, auth) => {
  let user = getStorage('user') ? JSON.parse(getStorage('user')) : {}
  let boo = false
  auth = auth || user.auth
  if (Object.keys(auth).length) {
    boo = Object.keys(auth).indexOf(btn) > - 1 ? auth[btn] === 1 : true
  } else {
    if (Object.keys(user).length && config.menu.manager.indexOf(user.EName) > -1) { // 超级管理员
      boo = true
    }
  }
  return boo
}

// 深拷贝
export const deepCopy = (target) => {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = [] // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepCopy(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if(target===null) {
      result = null
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if(target.constructor===RegExp){
      result = target
    }else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (let i in target) {
        result[i] = deepCopy(target[i])
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }
  // 返回最终结果
  return result
}

export const forEach = (arr, fn) => {
  if (!arr.length || !fn) return
  let i = -1
  let len = arr.length
  while (++i < len) {
    let item = arr[i]
    fn(item, i, arr)
  }
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */
export const getIntersection = (arr1, arr2) => {
  let len = Math.min(arr1.length, arr2.length)
  let i = -1
  let res = []
  while (++i < len) {
    const item = arr2[i]
    if (arr1.indexOf(item) > -1) res.push(item)
  }
  return res
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
  return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
  return targetarr.some(_ => arr.indexOf(_) > -1)
}

/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 */
export function oneOf (value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true
    }
  }
  return false
}

/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */
const isMillisecond = timeStamp => {
  const timeStr = String(timeStamp)
  return timeStr.length > 10
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */
const isEarly = (timeStamp, currentTime) => {
  return timeStamp < currentTime
}

/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
  return num < 10 ? '0' + num : num
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */
const getDate = (timeStamp, startType) => {
  const d = new Date(timeStamp * 1000)
  const year = d.getFullYear()
  const month = getHandledValue(d.getMonth() + 1)
  const date = getHandledValue(d.getDate())
  const hours = getHandledValue(d.getHours())
  const minutes = getHandledValue(d.getMinutes())
  const second = getHandledValue(d.getSeconds())
  let resStr = ''
  if (startType === 'year') resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
  else resStr = month + '-' + date + ' ' + hours + ':' + minutes
  return resStr
}

/**
 * @param {String|Number} timeStamp 时间戳
 * @returns {String} 相对时间字符串
 */
export const getRelativeTime = timeStamp => {
  // 判断当前传入的时间戳是秒格式还是毫秒
  const IS_MILLISECOND = isMillisecond(timeStamp)
  // 如果是毫秒格式则转为秒格式
  if (IS_MILLISECOND) Math.floor(timeStamp /= 1000)
  // 传入的时间戳可以是数值或字符串类型，这里统一转为数值类型
  timeStamp = Number(timeStamp)
  // 获取当前时间时间戳
  const currentTime = Math.floor(Date.parse(new Date()) / 1000)
  // 判断传入时间戳是否早于当前时间戳
  const IS_EARLY = isEarly(timeStamp, currentTime)
  // 获取两个时间戳差值
  let diff = currentTime - timeStamp
  // 如果IS_EARLY为false则差值取反
  if (!IS_EARLY) diff = -diff
  let resStr = ''
  const dirStr = IS_EARLY ? '前' : '后'
  // 少于等于59秒
  if (diff <= 59) resStr = diff + '秒' + dirStr
  // 多于59秒，少于等于59分钟59秒
  else if (diff > 59 && diff <= 3599) resStr = Math.floor(diff / 60) + '分钟' + dirStr
  // 多于59分钟59秒，少于等于23小时59分钟59秒
  else if (diff > 3599 && diff <= 86399) resStr = Math.floor(diff / 3600) + '小时' + dirStr
  // 多于23小时59分钟59秒，少于等于29天59分钟59秒
  else if (diff > 86399 && diff <= 2623859) resStr = Math.floor(diff / 86400) + '天' + dirStr
  // 多于29天59分钟59秒，少于364天23小时59分钟59秒，且传入的时间戳早于当前
  else if (diff > 2623859 && diff <= 31567859 && IS_EARLY) resStr = getDate(timeStamp)
  else resStr = getDate(timeStamp, 'year')
  return resStr
}

/**
 * @returns {String} 当前浏览器名称
 */
export const getExplorer = () => {
  const ua = window.navigator.userAgent
  const isExplorer = (exp) => {
    return ua.indexOf(exp) > -1
  }
  if (isExplorer('MSIE')) return 'IE'
  else if (isExplorer('Firefox')) return 'Firefox'
  else if (isExplorer('Chrome')) return 'Chrome'
  else if (isExplorer('Opera')) return 'Opera'
  else if (isExplorer('Safari')) return 'Safari'
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler)
      }
    }
  }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()

/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */
export const hasKey = (obj, key) => {
  if (key) return key in obj
  else {
    let keysArr = Object.keys(obj)
    return keysArr.length
  }
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  /* eslint-disable-next-line */
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}
