<template>
  <div class="menu-title">
    <template v-if="icon">
      <span :title="title">
         <i class="iconfont" :class="icon" v-if="icon.indexOf('icon-') > -1"></i>
         <Icon :type="icon" v-else></Icon>
      </span>
    </template>
    <Icon type="ios-apps" v-else></Icon>
    <template v-if="title && !isCollapse">
      <span slot="title"> {{title}}</span>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: "MenuItem",
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['sidebar']),
    isCollapse () {
      return this.sidebar.opened
    }

  }
}
</script>
