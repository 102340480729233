import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import router from './router/index-new'
import store from './store'
import ViewUI from 'view-design'
import Viewer from 'v-viewer'
// import VueAMap from 'vue-amap'
import dataV from '@jiaminghi/data-view'

import { VueJsonp } from 'vue-jsonp'
import { directive as clickOutside } from 'v-click-outside-x'
import importDirective from '@/directive'
import './permission'
import '@/assets/fontIcon/iconfont.css'
import 'view-design/dist/styles/iview.css'
import 'viewerjs/dist/viewer.css'
import '@/assets/css/common.less'
import 'animate.css'

Vue.use(dataV)
Vue.use(ViewUI)
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 99999
  }
})
Vue.use(VueJsonp)
Vue.config.productionTip = false
importDirective(Vue)
Vue.directive('clickOutside', clickOutside)
const app =new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app
