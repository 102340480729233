import axios from './api.request'
// 地图key
export const mapKey = '1c675b2abf70b92c1968e8330b96acf5'
//地图秘钥
export const SafeJsCode = 'cb153466bcfe923d64049d187a3345b7'
export const AMapWebServiceKey = '0e11539ed74c4cc831c09699360942a6' // 高德地图web服务key
// 地图中心点
export const center = {
    lng: 103.19,
    lat: 30.67,
    zoom: 6.5
}
// 达州市地理坐标为北纬30°75′-32°07′，东经106°94′-108°06′。
// 文件上传
export const uploadImage = '/api/SiteManagement/UploadImage'

// 登录
export const login = (data) => {
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/UserLogin',
        data,
        method: 'post'
    })
}
export const loginNew = (data) => { //登录-新 返回token
    return axios.request({
        noToken: true,
        url: '/api/UserInfo/UserLoginPage',
        data,
    })
}
export const logout = (params) => { // 退出登录
    return axios.request({
        url: '/api/UserInfo/LogOut',
        params,
        method: 'get'
    })
}
export const getUserInfo = (params) => { // 获取用户信息
    return axios.request({
        url: '/api/UserInfo/GetUserInfo',
        params,
        method: 'get'
    })
}
/*电站--start*/
export const querySite = (data) => { // 根据条件信询电站信息
    return axios.request({

        url: '/api/SiteManagement/GetSiteManagementSearch',
        data
    })
}
export const querySiteAll = (params) => { // 查询站点(不分页)
    return axios.request({

        url: '/api/SiteManagement/GetAllSiteManagement',
        params,
        method: 'get'
    })
}
export const querySiteById = (params) => { // 根据id查询信息
    return axios.request({

        url: '/api/SiteManagement/GetById',
        params,
        method: 'get'
    })
}
export const siteAdd = (data) => { // 新增
    return axios.request({

        url: '/api/SiteManagement/Insert',
        data
    })
}
export const siteUpdate = (data) => { // 修改
    return axios.request({

        url: '/api/SiteManagement/Update',
        data
    })
}
export const siteUpdateState = (params) => { // 根据Id修改状态
    return axios.request({

        url: '/api/SiteManagement/UpdateState',
        params,
        method: 'get'
    })
}
export const updateEquipmentLngLatByCode = (params) => { // 根据站点Code修改桩坐标系
    return axios.request({
        url: '/api/SiteManagement/UpdateEquipmentLngLat',
        params,
        method: 'get'
    })
}
export const chargePointAdd = (data) => { // 充电桩新增
    return axios.request({

        url: '/api/ChargePointManagement/Insert',
        data
    })
}
export const queryChargePoint = (data) => { // 充电桩查询
    return axios.request({

        url: 'api/ChargePointManagement/GetChargePointManagementSearch',
        data
    })
}
export const chargePointUpdate = (data) => { // 充电桩修改
    return axios.request({

        url: '/api/ChargePointManagement/Update',
        data
    })
}
export const chargeRateSetting = (data) => { // 充电桩下发费率命令
    return axios.request({

        url: '/api/Socket/SocketBillingSettings',
        data
    })
}
export const chargeRateSettingWM = (data) => { // 充电桩下发费率命令-万马
    return axios.request({
        url: '/api/SocketWM/SocketBillingSettingsWM',
        data
    })
}
export const chargePointUpdateState = (params) => { // 根据Id修改状态
    return axios.request({

        url: '/api/ChargePointManagement/UpdateState',
        params,
        method: 'get'
    })
}
export const chargePointUpdateRate = (params) => { // 根据Id修改所属费率组
    return axios.request({

        url: '/api/ChargePointManagement/UpdateRateId',
        params,
        method: 'get'
    })
}
export const getHeartbeatFront = (params) => { // 获取桩信息的心跳和时间
    return axios.request({

        url: '/api/Socket/SocketCheckHeartbeatFront',
        params,
        method: 'get'
    })
}
export const addElectricGun = (data) => {//新增电枪
    return axios.request({

        url: '/api/ElectricManagement/Insert',
        data
    })
}
export const updateElectricGun = (data) => {//修改电枪
    return axios.request({

        url: '/api/ElectricManagement/Update',
        data
    })
}
export const getElectricSearch = (data) => { // 获取电枪列表
    return axios.request({

        url: '/api/ElectricManagement/GetElectricManagementSearch',
        data
    })
}
export const getEleByChargeId = (params) => { // 根据电桩Id获取电枪信息
    return axios.request({

        url: '/api/ElectricManagement/GetByChargeId',
        params,
        method: 'get'
    })
}
export const getRealData = (params) => { // 实时监测电枪数据
    return axios.request({

        url: '/api/Socket/SocketGetChargingRealTimeStatus',
        params,
        method: 'get'
    })
}
export const getRemoteReboot = (params) => { // 远程重启
    return axios.request({

        url: '/api/Socket/SocketRemoteReboot',
        params,
        method: 'get'
    })
}
export const getCheckTime = (params) => { // 核对时间(充电桩与服务器核对时间一天一次)
    return axios.request({

        url: '/api/Socket/SocketCheckTime',
        params,
        method:'get'
    })
}
export const setQRCode = (params) => { // 电枪下发二维码
    return axios.request({

        url: '/api/Socket/IssueQRCode',
        params,
        method: 'get'
    })
}
/*电站--end*/
/*费率组管理--start*/
export const chargeRatesQuery = (data) => {
    return axios.request({

        url: '/api/ChargeRates/GetChargeRatesSearch',
        data
    })
}
export const chargeRatesAdd = (data) => { // 新增
    return axios.request({

        url: '/api/ChargeRates/Insert',
        data
    })
}
export const chargeRatesUpdate = (data) => { // 修改
    return axios.request({

        url: '/api/ChargeRates/Update',
        data
    })
}
export const updateRateIdBySiteIs = (params) => { // 下发费率
    return axios.request({

        timeout: 60000,
        url: '/api/ChargePointManagement/UpdateRateIdBySiteIds',
        params,
        method: 'get'
    })
}
export const chargeRatesUpdateState = (params) => { // 根据id修改状态
    return axios.request({

        url: '/api/ChargeRates/UpdateState',
        params,
        method: 'get'
    })
}
export const chargeRateImport = (data) => { // 费率导入
    return axios.request({
        url: '/api/ChargeRates/UploadExcel',
        data
    })
}
/*费率组管理--start*/
//获取费率
export const feePlanGetPage = (params) =>{
    return axios.request({
        url: '/api/FeePlan/GetPage',
        params,
        method: 'get'
    })
}
// 查询详情
export const FeePlanGetDetail = (params)=>{
    return axios.request({
        url: '/api/FeePlan/GetDetail',
        params,
        method: 'get'
    })
}
//获取未下发的站点
export const GetSiteNotFeePlan = (params)=>{
    return axios.request({
        url: '/api/FeePlan/GetSiteNotFeePlan',
        params,
        method: 'get'
    })
}
// 新增费率
export const feePlanCreate = (data) => {
    return axios.request({
        url: '/api/FeePlan/Create',
        data
    })
}
//修改费率
export const feePlanEdit = (data) => {
    return axios.request({
        url: '/api/FeePlan/Edit',
        data
    })
}
// 下发费率
export const FeePlanPushById = (data)=>{
    return axios.request({
        url: '/api/FeePlan/PushById',
        data
    })
}
//删除费率
export const feePlanDel = (data) => {
    return axios.request({
        url: '/api/FeePlan/Delete',
        data
    })
}
//获取充电费列表
export const ChargingFeeGetPage = (params)=>{
    return axios.request({
        url: '/api/ChargingFee/GetPage',
        params,
        method: 'get'
    })
}
//新增电费
export const ChargingFeeCreate = (data)=>{
    return axios.request({
        url: '/api/ChargingFee/Create',
        data
    })
}
//查询选择
export const ChargingFeeGetSelect = (params)=>{
    return axios.request({
        url: '/api/ChargingFee/GetSelect',
        params,
        method: 'get'
    })
}
//获取充电费详情
export const ChargingFeeGetDetail = (params)=>{
    return axios.request({
        url: '/api/ChargingFee/GetDetail',
        params,
        method: 'get'
    })
}
//获取充电费绑定站点
export const ChargingFeeSiteInPlan = (params)=>{
    return axios.request({
        url: '/api/ChargingFee/GetSiteInPlan',
        params,
        method: 'get'
    })
}
//修改充电费
export const ChargingFeeEdit = (data)=>{
    return axios.request({
        url: '/api/ChargingFee/EditAndSites',
        data
    })
}
// 下发费率
export const ChargingFeePushById = (params)=>{
    return axios.request({
        url: '/api/ChargingFee/PushById',
        params,
        method: 'get'
    })
}
//删除充电费
export const ChargingFeeDelete = (data)=>{
    return axios.request({
        url: '/api/ChargingFee/Delete',
        data
    })
}
//获取服务费列表
export const ServiceFeeGetPage = (params)=>{
    return axios.request({
        url: '/api/ServiceFee/GetPage',
        params,
        method: 'get'
    })
}
//新增服务费
export const ServiceFeeCreate = (data)=>{
    return axios.request({
        url: '/api/ServiceFee/Create',
        data
    })
}
//查询选择
export const ServiceFeeGetSelect = (params)=>{
    return axios.request({
        url: '/api/ServiceFee/GetSelect',
        params,
        method: 'get'
    })
}
//服务费详情
export const ServiceFeeGetDetail = (params)=>{
    return axios.request({
        url: '/api/ServiceFee/GetDetail',
        params,
        method: 'get'
    })
}
//获取服务费绑定站点
export const ServiceFeeSiteInPlan = (params)=>{
    return axios.request({
        url: '/api/ServiceFee/GetSiteInPlan',
        params,
        method: 'get'
    })
}
//修改服务费
export const ServiceFeeEdit = (data)=>{
    return axios.request({
        url: '/api/ServiceFee/EditAndSites',
        data
    })
}
// 下发费率
export const ServiceFeePushById = (params)=>{
    return axios.request({
        url: '/api/ServiceFee/PushById',
        params,
        method: 'get'
    })
}
//删除服务费
export const ServiceFeeDelete = (data)=>{
    return axios.request({
        url: '/api/ServiceFee/Delete',
        data
    })
}
//获取时段
export const TimeSliceGetPage = (params)=>{
    return axios.request({
        url: '/api/TimeSlice/GetPage',
        params,
        method: 'get'
    })
}
//新增时段
export const TimeSliceCreate = (data)=>{
    return axios.request({
        url: '/api/TimeSlice/Create',
        data
    })
}
//查询选择
export const TimeSliceGetSelect = (params)=>{
    return axios.request({
        url: '/api/TimeSlice/GetSelect',
        params,
        method: 'get'
    })
}
//时段详情
export const TimeSliceGetDetail = (params)=>{
    return axios.request({
        url: '/api/TimeSlice/GetDetail',
        params,
        method: 'get'
    })
}
//获取时段绑定站点
export const TimeSliceSiteInPlan = (params)=>{
    return axios.request({
        url: '/api/TimeSlice/GetSiteInPlan',
        params,
        method: 'get'
    })
}
//修改时段
export const TimeSliceEdit = (data)=>{
    return axios.request({
        url: '/api/TimeSlice/EditAndSites',
        data
    })
}
// 下发费率
export const TimeSlicePushById = (params)=>{
    return axios.request({
        url: '/api/TimeSlice/PushById',
        params,
        method: 'get'
    })
}
//删除时段
export const TimeSliceDelete = (data)=>{
    return axios.request({
        url: '/api/TimeSlice/Delete',
        data
    })
}
//获取当前已绑定站点
// export const GetInSite = (params)=>{
//     return axios.request({
//         url: '/api/FeePlan/GetInSite',
//         params,
//         method: 'get'
//     })
// }
//获取当前未绑定站点
// export const GetNotInSite = (params)=>{
//     return axios.request({
//         url: '/api/FeePlan/GetNotInSite',
//         params,
//         method: 'get'
//     })
// }
//绑定到站点
export const SetSiteFeePlan = (data)=>{
    return axios.request({
        url: '/api/FeePlan/SetSiteFeePlan',
        data
    })
}
/*费率组管理--end*/
/*订单管理--start*/
export const getOrderList = (data) => { // 获取订单列表
    return axios.request({

        url: '/api/OrderInformation/GetOrderInformationSearch',
        data
    })
}
export const getRealOrderInfo = () => { // 获取实时订单数据
    return axios.request({

        url: '/api/Socket/SocketGetSocketRealTimeDataFront',
        method: 'get'
    })
}
/*订单管理--end*/
/*用户管理--start*/
export const getUserList = (data) => { // 获取用户列表
    return axios.request({
        url: '/api/UserInfo/GetUserInfoSearch',
        data
    })
}
export const userListUpdate = (data) => { // 根据用户id修改用户音系
    return axios.request({

        url: '/api/UserInfo/UpdateUserInfo',
        data
    })
}
export const updatePwd = (data) => { // 修改密码
    return axios.request({

        url: '/api/UserInfo/UpdateUserPwd',
        data
    })
}
export const userReconcileAdd = (data) => { // 新增对账用户
    return axios.request({

        url: '/api/UserInfo/InserUserReconciliation',
        data
    })
}
export const userDel = (params) => { // 删除用户
    return axios.request({

        url: '/api/UserInfo/DeleteUser',
        params,
        method: 'get'
    })
}
export const insertRecharge = (data) => {
    return axios.request({

        url: '/api/UserRechargeRecord/InsertUserRechargeRecord',
        data
    })
}
export const getMemberList = (params) => { // 获取会员等级
    return axios.request({

        url: '/api/MemberLevelManagement/GetAll',
        params,
        method: 'get'
    })
}
export const memberAdd = (data) => { // 会员等级新增
    return axios.request({

        url: '/api/MemberLevelManagement/Insert',
        data
    })
}
export const memberUpdate = (data) => { // 会员等级修改
    return axios.request({

        url: '/api/MemberLevelManagement/Update',
        data
    })
}
export const userRechargeQuery = (params) => { // 流水记录查询
    return axios.request({
        url: '/api/UserRechargeRecord/GetUserRechargeRecordSearch',
        params,
        method: 'get'
    })
}
export const userRechargeAdd = (data) => { // 充值记录新增
    return axios.request({

        url: '/api/UserRechargeRecord/InsertUserRechargeRecord',
        data
    })
}
export const userRechargeUpdate = (data) => { // 充值记录修改
    return axios.request({

        url: '/api/UserRechargeRecord/Update',
        data
    })
}
export const checkCarInfo = (params) => { // 审核用户车辆信息
    return axios.request({

        url: '/api/UserInfo/UpdateAuditStatus',
        params,
        method: 'get'
    })
}

export const getRolesQuery = (data) => { // 查询角色管理列表
    return axios.request({
        url: '/api/Roles/Query',
        data
    })
}
export const getRolesQueryNoPage = (params) => { // 查询角色列表-不分页
    return axios.request({
        url: '/api/Roles/Get',
        params,
        method: 'get'
    })
}
export const rolesAdd = (data) => { // 角色新增
    return axios.request({
        url: '/api/Roles/Create',
        data
    })
}
export const rolesMod = (data) => { // 角色修改
    return axios.request({
        url: '/api/Roles/Update',
        data
    })
}
export const rolesDel = (params) => { // 角色删除
    return axios.request({
        url: '/api/Roles/Delete',
        params,
        method: 'get'
    })
}
export const getRoleUsersQuery = (params) => { // 用户角色查询
    return axios.request({
        url: '/api/RoleUsers/Get',
        params,
        method: 'get'
    })
}
export const roleUsersAdd = (data) => { // 用户角色新增
    return axios.request({
        url: '/api/RoleUsers/Create',
        data
    })
}
export const roleUsersDel = (data) => { // 用户角色删除
    return axios.request({
        url: '/api/RoleUsers/Delete',
        data
    })
}
export const getExcludeUsers = (params) => { // 查询未添加角色的用户列表
    return axios.request({
        url: '/api/RoleUsers/GetExcludeUsers',
        params,
        method: 'get'
    })
}
export const getPowersGroupQuery = (params) => { // 按分组汇总查询权限
    return axios.request({
        url: '/api/RolePowers/GetPowersGroup',
        params,
        method: 'get'
    })
}
export const getRolePowersQuery = (params) => { // 查询角色权限
    return axios.request({
        url: '/api/RolePowers/GetRolePowers',
        params,
        method: 'get'
    })
}
export const setRolePowersMod = (data) => { // 更新角色权限
    return axios.request({
        url: '/api/RolePowers/UpdateRolePowers',
        data
    })
}
export const GetRelationTree = (params) => { // 用户组织权限
    return axios.request({
        url: '/api/Authorize/GetRelationTree',
        params,
        method: 'get'
    })
}
export const Authorize = (data) => { // 更新用户角色权限
    return axios.request({
        url: '/api/Authorize',
        data
    })
}

export const definiteList = (params) => { // 流水明细
    return axios.request({
        url: '/api/UserInfo/GetDetailedAccountByParm',
        params,
        method: 'get'
    })
}

/*用户管理--end*/
/*统计--start*/
// 个人消费统计
export const getPersonConsume = (data) => { // 个人消费统计
    return axios.request({

        url: '/api/OrderInformation/GetPersonalMonthlyRanking',
        data
    })
}
export const personConsumeExport = (data) => { // 个人消费统计导出
    return axios.request({

        url: '/api/OrderInformation/ExportExcelSummary',
        data,
        responseType: 'blob'
    })
}
// 订单统计
export const getOrderCharts = (data) => { // 订单汇总图表
    return axios.request({

        url: '/api/OrderInformation/GetOrderInformationSummaryChart',
        data,
        timeout: 60000
    })
}
export const getOrderSummary = (data) => { // 订单汇总列表
    return axios.request({

        url: '/api/OrderInformation/GetOrderInformationSummary',
        data
    })
}
export const getOrderSummaryChart = (data) => { // 订单月度汇总图表
    return axios.request({
        url: '/api/OrderInformation/GetNewOrderInformationSummaryChart',
        data,
        timeout: 60000
    })
}
export const getOrderSummaryStatistics = (data) => { // 订单月度汇总列表
    return axios.request({

        url: '/api/OrderInformation/GetNewOrderInformationSummary',
        data
    })
}
export const getOrderSummaryStatisticsTime = (data) => { // 订单汇总统计
    return axios.request({

        url: '/api/OrderInformation/GetOrderInformationMonthTotal',
        data
    })
}
export const orderSummaryExport = (data) => { // 订单汇总导出
    return axios.request({

        url: '/api/OrderInformation/ExportExcelSummary',
        data,
        responseType: 'blob'
    })
}
export const orderSummaryExportTime = (data) => { // 订单汇总导出-新
    return axios.request({

        url: '/api/OrderInformation/NewExportExcelSummary',
        data,
        responseType: 'blob'
    })
}
export const orderSummaryExportNew = (data) => { // 订单月度汇总导出
    return axios.request({
        url: '/api/OrderInformation/ExportExcelNewSummary',
        data,
        responseType: 'blob'
    })
}
export const orderDetailList = (data) => { // 订单明细列表
    return axios.request({
        url: '/api/OrderInformation/GetOrderInformationNewSearch',
        data
    })
}
export const orderDetailListNew = (data) => { // 订单明细列表-新
    return axios.request({
        url: '/api/OrderInformation/GetNewOrderInformationSearch',
        data
    })
}
export const orderDetailExport = (data) => { // 订单明细导出
    return axios.request({

        url: '/api/OrderInformation/ExportExcelDetailed',
        data,
        responseType: 'blob',
        timeout: 60000
    })
}
export const orderDetailExportNew = (data) => { // 订单明细导出-新
    return axios.request({

        url: '/api/OrderInformation/ExportExcelNewDetailed',
        data,
        responseType: 'blob',
        timeout: 60000
    })
}
export const flowDetails = (params) => { // 导出用户流水明细
    return axios.request({
        url: '/api/UserRechargeRecord/UserRechargeRecordExportAll',
        params,
        method: 'get',
        responseType: 'blob',
        timeout: 60000
    })
}
// 日报/月报统计
export const orderStatisticsCountByTime = (params) => { // 交易笔数/交易人数统计
    return axios.request({
        url: '/api/AppletStatistical/GetTransactionDetailsInDays',
        params,
        method: 'get'
    })
}
export const orderStatisticsAmountByTime = (params) => { // 以时间统计交易额统计
    return axios.request({
        url: '/api/AppletStatistical/GetDealChart',
        params,
        method: 'get'
    })
}
export const orderStatisticsAmountBySite = (params) => {// 以时间统计交易笔数统计
    return axios.request({
        url: '/api/AppletStatistical/GetSiteStatDealDetail',
        params,
        method: 'get'
    })
}
export const orderStatisticsList = (params) => { // 订单日/月报统计列表
    return axios.request({
        url: '/api/AppletStatistical/GetSiteDealDetials',
        params,
        method: 'get'
    })
}
export const orderTotalStatisticsByDay = (params) => { // 订单日报统计-总
    return axios.request({
        url: '/api/AppletStatistical/GetDayDealStat',
        params,
        method: 'get'
    })
}
export const orderTotalCountByMonth = (params) => { // 订单月报统计交易笔数
    return axios.request({
        url: '/api/AppletStatistical/GetDealOverview',
        params,
        method: 'get'
    })
}
export const orderTotalPeopleByMonth = (params) => { // 订单月报统计交易人数
    return axios.request({
        url: '/api/AppletStatistical/GetDealOverviewPeoples',
        params,
        method: 'get'
    })
}
export const electricStatisticsBySite = (params) => { // 按电站统计电量
    return axios.request({
        url: '/api/AppletStatistical/GetElectirc',
        params,
        method: 'get'
    })
}
export const electricStatisticsByTime = (params) => { // 按时间段统计电量
    return axios.request({
        url: '/api/AppletStatistical/GetElectricTimeBucket',
        params,
        method: 'get'
    })
}
export const electricStatisticsList = (params) => { // 电量统计列表
    return axios.request({
        url: '/api/AppletStatistical/GetElectricList',
        params,
        method: 'get'
    })
}
export const chargeStatisticsTotal = (params) => { // 充电桩状态统计-总览
    return axios.request({
        url: '/api/AppletStatistical/GetMachineStatus',
        params,
        method: 'get'
    })
}
export const chargeStatisticsBySite = (params) => { // 按电站统计充电桩状态
    return axios.request({
        url: '/api/AppletStatistical/GetSitePoitState',
        params,
        method: 'get'
    })
}
export const chargeStatisticsByTime = (params) => { // 按时间段统计充电桩状态
    return axios.request({
        url: '/api/AppletStatistical/GetSitePontBucket',
        params,
        method: 'get'
    })
}
export const chargeStatisticsList = (params) => { // 充电桩状态统计列表
    return axios.request({
        url: '/api/AppletStatistical/GetSite',
        params,
        method: 'get'
    })
}
/*统计--end*/
/*运营商管理--start*/
export const companyAdd = (data) => { // 运营商新增
    return axios.request({

        url: '/api/Company/Insert',
        data
    })
}
export const companyQuery = (data) => { // 运营商查询
    return axios.request({
        url: '/api/Company/GetAllCompany',
        data
    })
}
export const companyQueryGet = (params) => { // 运营商查询不分页
    return axios.request({

        url: '/api/Company/GetAllCompany',
        params,
        method: 'get'
    })
}
export const getThreeCompanyQuery = (data) => { // 第三方对接公司
    return axios.request({

        url: '/api/Company/GetAllForeignCompany',
        data
    })
}
export const setThreeAvailableAmount = (params) => { // 设置第三方允许充电最低余额
    return axios.request({

        url: '/api/Company/UpdateAvailableBalance',
        params,
        method: 'get'
    })
}
export const setThreeCompanyState = (params) => { // 设置第三方公司状态
    return axios.request({

        url: '/api/Company/UpdateUserState',
        params,
        method: 'get'
    })
}
export const companyMod = (data) => { // 运营商修改
    return axios.request({

        url: '/api/Company/Update',
        data
    })
}
export const GetForeignById = (params)=>{ //获取列表
    return axios.request({

        url: '/api/Company/GetForeignById',
        params,
        method: 'get'
    })
}
export const MiniAmount = (params)=>{ //最小金额
    return axios.request({

        url: '/api/Company/UpdateMinimumUsageAmount',
        params,
        method: 'get'
    })
}
export const setDiscount = (params)=>{ //折扣率
    return axios.request({

        url: '/api/Company/UpdateDrainageDiscount',
        params,
        method: 'get'
    })
}
export const setRechargeRecord = (data) => { // 充值
    return axios.request({

        url: '/api/Company/InsertUserRechargeRecord',
        data
    })
}
export const setSlottingAllowance = (params) =>{ //设置第三方平台结算通道费
    return axios.request({

        url: '/api/Company/UpdateSlottingAllowance',
        params,
        method: 'get'
    })
}

export const rechargeRecord = (data)=>{ //新充值记录（未使用）
    return axios.request({

        url: '/api/UserRechargeRecord/HLHTUserRechargeRecordSearch',
        data,
    })
}

export const GetHlhtSelectSiteManagement = (params) =>{ //设置第三方平台结算通道费
    return axios.request({

        url: '/api/SiteManagement/GetHlhtSelectSiteManagement',
        params,
        method: 'get'
    })
}
export const UpdateHlhtSelectSiteManagement = (data)=>{ //新充值记录（未使用）
    return axios.request({

        url: '/api/SiteManagement/UpdateHlhtSelectSiteManagement',
        data,
    })
}
// 运营商结束
export const withdrawWaitingQuery = (params) => { // 待提现记录查询(分站点)
    return axios.request({

        url: '/api/OperatorSettlement/GenerateSettlementList',
        params,
        method: 'get'
    })
}
export const withdrawWaitingQuerySummary = (params) => { // 待提现记录查询(汇总)
    return axios.request({

        url: '/api/OperatorWithdrawal/GetWithdrawalSummary',
        params,
        method: 'get'
    })
}
export const withdrawAdd = (data) => { // 提现申请
    return axios.request({

        url: '/api/OperatorWithdrawal/InsertOperatorSettlement',
        data
    })
}
export const withdrawQuery = (data) => { // 运营商提现记录查询
    return axios.request({

        url: '/api/OperatorWithdrawal/GetAllOperatorWithdrawal',
        data
    })
}
export const withdrawApplyOk = (params) => { // 修改提现记录状态
    return axios.request({

        url: '/api/OperatorWithdrawal/UpdateStatus',
        params,
        method: 'get'
    })
}
export const getSumOperatorWithdrawal = (params) => { // 已提现总金额
    return axios.request({

        url: '/api/OperatorWithdrawal/GetSumOperatorWithdrawal',
        params,
        method: 'get'
    })
}
export const getKDCheckOrder = (params) => { // 查询待对账的快电订单
    return axios.request({

        url: '/api/OrderInformation/GetKdCheckOrderParmer',
        params,
        method: 'get'
    })
}
export const getKDGenerateOrder = (params) => { // 生成快电对账单
    return axios.request({

        url: '/api/OrderInformation/GetKdGenerateOrder',
        params,
        method: 'get'
    })
}
export const getMdxServiceRateQuery = (params) => { // 满电行服务费率查询
    return axios.request({
        url: '/api/MdxServerRate/GetMdxServerRateAll',
        params,
        method: 'get'
    })
}
/*运营商管理--end*/
/*活动管理--start*/
export const getActivityQuery = (data) => { // 活动查询
    return axios.request({

        url: '/api/ActivityList/GetActivityListSearch',
        data
    })
}
export const setActivityAdd = (data) => { //活动新增
    return axios.request({

        url: '/api/ActivityList/Insert',
        data
    })
}
export const setActivityUpdate = (data) => { //活动修改
    return axios.request({

        url: '/api/ActivityList/Update',
        data
    })
}
export const setActivityState = (params) => {
    return axios.request({
        url: '/api/ActivityList/UpdateState',
        params,
        method: 'get'
    })
}
export const InsertVersion = (data) => { //新增版本号
    return axios.request({
        url: '/mdxapi/VersionManagement/Insert',
        data
    })
}
export const UpdateVersion = (data) => {//修改版本号
    return axios.request({
        url: '/mdxapi/VersionManagement/Update',
        data
    })
}
export const GetByVersionNumber = (params) => {//获取版本号
    return axios.request({
        url: '/mdxapi/VersionManagement/GetByVersionNumber',
        params,
        method: 'get'
    })
}

/*活动管理--end*/
// 分站获取基础url
export const getBaseReqUrl = (params) => { // 分页
    return axios.request({
        url: '/api/SocketBase/GetSocketBases',
        params,
        method: 'get'
    })
}
export const getBaseReqUrlNoPage = (params) => { // 不分页
    return axios.request({
        url: '/api/SocketBase/GetValidSocketBases',
        params,
        method: 'get'
    })
}
export const GetInvoiceListsWeb = (params) => {//获取所有开票数据
    return axios.request({
        url: '/api/InvoiceMent/GetInvoiceListsWeb',
        params,
        method: 'get'
    })
}
export const DeleteInvoiceInfo = (params) => {//删除发票
    return axios.request({
        url: '/api/InvoiceMent/DeleteInvoiceInfo',
        params,
        method: 'get'
    })
}
//开票上传接口
export const uploadPdf = '/api/InvoiceMent/UploadImgDefault'

export const UpdateInvoiceInfo = (data) => {//删除发票
    return axios.request({
        url: '/api/InvoiceMent/UpdateInvoiceInfo',
        data
    })
}

export const SendEmial = (data) => {//发送邮件
    return axios.request({
        url: '/api/InvoiceMent/InvoiceMentSendEmialMain',
        data
    })
}
export const TradeRefund = (data) => {//申请退款
    return axios.request({
        url: '/api/WxMdxAppInterface/GetWxAppAlipayTradeRefund',
        data
    })
}
export const getSendSms = (data) => { // 发送验证码
    return axios.request({
        url: '/api/TxySms/TxySendSms',
        data
    })
}
/*系统管理--start*/
export const getMenusQuery = (data) => { // 查询菜单
    return axios.request({
        url: '/api/Menus/Query',
        data
    })
}
export const setMenusAdd = (data) => { // 菜单新增
    return axios.request({
        url: '/api/Menus/Create',
        data
    })
}
export const setMenusMod = (data) => { // 菜单编辑
    return axios.request({
        url: '/api/Menus/Update',
        data
    })
}
export const setMenusDel = (params) => { // 菜单删除
    return axios.request({
        url: '/api/Menus/Delete',
        params,
        method: 'get'
    })
}
export const getRoutesMenus = (params) => { // 获取用户菜单生成路由
    return axios.request({
        url: 'api/Menus/GetUserMenus',
        params,
        method: 'get'
    })
}
export const getPowersQuery = (data) => { //查询权限列表
    return axios.request({
        url: '/api/Powers/Query',
        data
    })
}
export const setPowersAdd = (data) => { // 添加权限
    return axios.request({
        url: '/api/Powers/Create',
        data
    })
}
export const setPowersMod = (data) => { // 编辑权限
    return axios.request({
        url: '/api/Powers/Update',
        data
    })
}
export const setPowersDel = (params) => { // 删除权限
    return axios.request({
        url: '/api/Powers/Delete',
        params,
        method: 'get'
    })
}
export const getDictQuery = (data) => { // 查询字典
    return axios.request({
        url: '/api/Option/Query',
        data
    })
}
export const getDictQueryByGroup = (params) => { // 根据分组查询字典
    return axios.request({
        url: '/api/Option/GetOption',
        params,
        method: 'get'
    })
}
export const setDictAdd = (data) => { // 添加字典
    return axios.request({
        url: '/api/Option/Create',
        data
    })
}
export const setDictMod = (data) => { // 编辑字典
    return axios.request({
        url: '/api/Option/Update',
        data
    })
}
export const setDictDel = (params) => { // 删除字典
    return axios.request({
        url: '/api/Option/Delete',
        params,
        method: 'get'
    })
}
/*系统管理--end*/
