<template>
  <div class="sidebar-logo-container"
       :class="{collapse: collapse}"
       :style="{backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg}">
    <transition name="sidebarLogoFade">
        <div class="sidebar-logo-link" @click="$router.push('/home')">
          <img :src="logo" class="sidebar-logo" v-if="logo" />
          <h1 class="sidebar-title" :style="{color: sideTheme === 'theme-dark' ? variables.sidebarTitle : variables.sidebarLightTitle}" v-if="!collapse">{{title}}</h1>
        </div>
    </transition>
  </div>
</template>

<script>
import variables from '@/config'
import settings from '@/config'
import logoImg from '@/assets/logo.png'
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    variables () {
      return variables
    },
    sideTheme () {
      return settings.sideTheme
    }
  },
  data () {
    return {
      title: settings.title,
      logo: logoImg
    }
  }
}
</script>

<style scoped lang="less">
  .sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
  }

  .sidebarLogoFade-enter,
  .sidebarLogoFade-leave-to {
    opacity: 0;
  }

  .sidebar-logo-container {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    overflow: hidden;
    border-bottom: 1px solid rgba(255,255,255,.25);
    & .sidebar-logo-link {
      height: 100%;
      width: 100%;
      cursor: pointer;

      & .sidebar-logo {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 12px;
      }

      & .sidebar-title {
        display: inline-block;
        margin: 0;
        color: #fff;
        font-weight: 600;
        line-height: 50px;
        font-size: 16px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        vertical-align: middle;
      }
    }

    &.collapse {
      .sidebar-logo {
        margin-right: 0;
      }
    }
  }
</style>
