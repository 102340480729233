import { loginNew, getUserInfo, logout } from '@/libs/api'
import { getStorage, setStorage, removeStorageAll } from '@/libs/tools'
import app from '@/main'
import permission from "./permission";

const user = {
  state: {
    token: getStorage('token'),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginNew(userInfo).then(res => {
          if (res.data.code === 0) {
            let token = res.data.data
            setStorage('token', token)
            commit('SET_TOKEN', token)
            resolve()
          } else {
            app.$Message.error(res.data.message)
            reject()
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.data.code === 0) {
            let user = res.data.data
            const avatar = user.avatarUrl
            commit('SET_ROLES', user.userPower)
            commit('SET_PERMISSIONS', user.userPower)
            commit('SET_NAME', user.userName)
            commit('SET_AVATAR', avatar)

            user.companyId && (user.companyId = user.companyId.toString())
            user.siteIds && (user.siteId = user.siteIds.toString())
            setStorage('user', JSON.stringify(user))
            resolve()
          } else {
            app.$Message.error(res.data.message)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then((res) => {
          if (res.data.code === 0) {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_NAME','')
            commit('SET_AVATAR', '')
            removeStorageAll()
            resolve()
          } else {
            reject(res.data.message)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    setRoles ({commit}, roles) {
      commit('SET_ROLES', roles)
    },
    setPermissions ({ commit}, permissions) {
      commit('SET_PERMISSIONS', permissions)
    }
  }
}

export default user
