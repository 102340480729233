import { getRoutesMenus } from '@/libs/api'
import Main from '@/views/Main'
import { constantRoutes } from '@/router/index-new'
import { deepCopy }  from '@/libs/tools'
import app from '@/main'
const permission = {
    state: {
        routes: [],
        addRoutes: [],
        defaultRoutes: [],
        topbarRoutes: [],
        sidebarRoutes: []
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.addRoutes = routes
            state.routes = constantRoutes.concat(routes)
        },
        SET_DEFAULT_ROUTES: (state, routes) => {
            state.defaultRoutes = routes
        },
        SET_TOPBAR_ROUTES: (state, routes) => {
            const index = [{
                path: 'index',
                name: 'index',
                meta: {
                    name: '统计',
                    icon: 'icon-caidan'
                }
            }]
            state.topbarRoutes = routes.concat(index)
        },
        SET_SIDEBAR_ROUTES: (state, routes) => {
            state.sidebarRoutes = routes
        }
    },
    actions: {
        GenerateRoutes ({commit}) {
            commit('SET_SIDEBAR_ROUTES', [])
            return new Promise((resolve) => {
                getRoutesMenus({
                    system: 0
                }).then((res) => {
                    if (res.data.code === 0) {
                        let sData = deepCopy(res.data.data)
                        let rData = deepCopy(res.data.data)
                        let sidebarRoutes = filterAsyncRouter(sData)
                        let rewriteRoutes = filterAsyncRouter(rData, false, true)
                        commit('SET_ROUTES', rewriteRoutes)
                        commit('SET_SIDEBAR_ROUTES', constantRoutes.concat(sidebarRoutes))
                        commit('SET_DEFAULT_ROUTES', sidebarRoutes)
                        commit('SET_TOPBAR_ROUTES', sidebarRoutes)
                        resolve(rewriteRoutes)
                    } else {
                        app.$Message.error(res.data.message)
                    }
                })
            })
        }
    }
}
function filterAsyncRouter (asyncRouterMap, lastRouter, type = false) {
    return asyncRouterMap.filter((route) => {
        if (type && route.children) {
            route.children = filterChildren(route.children)
        }
        if (!type) {
            if (lastRouter) {
                route.path = lastRouter.path + '/' + route.path
            }
        }
        if (route.component) {
            if (route.component === 'Main') {
                route.component = Main
            } else {
                route.component = loadView(route.component)
            }
        }
        if (route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route, type)
        } else {
            delete route['children']
            delete route['redirect']
        }
        return true
    })
}
function filterChildren (childrenMap, lastRouter) {
    let children = []
    childrenMap.forEach((el, index) => {
        if (el.children && el.children.length) {
            if (el.component === 'Main') {
                el.children.forEach((c) => {
                    c.path = el.path + '/' + c.path
                    if (c.children && c.children.length) {
                        children = children.concat(filterChildren(c.children, c))
                        return
                    }
                    children.push(c)
                })
                return
            }
        }
        if (lastRouter) {
            el.path = lastRouter.path + '/' + el.path
        }
        children = children.concat(el)
    })
    return children
}
export const loadView = (view) => {
    return (resolve) => require([`@/views/${view}`], resolve)
}
export default permission
