<template>
  <div v-if="!item.hidden">
    <template v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
        <MenuItem :class="{'submenu-title-noDropdown': !isNest}"
                  :name="basePath"
                  :to="basePath"
                  :target="item.isFrame ? '_blank' : '_self'">
          <item :icon="iconName(Object.assign({}, onlyOneChild, item))" :title="onlyOneChild.name"></item>
        </MenuItem>
    </template>
    <Submenu v-else ref="subMenu" :name="item.path">
      <template slot="title">
        <item :icon="iconName(item)" :title="item.name"></item>
      </template>
      <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :is-nest="true"
          :item="child"
          :base-path="child.path"
          class="nest-menu">
      </sidebar-item>
    </Submenu>
  </div>
</template>

<script>
import path from 'path'
import Item from './Item'
import FixOSBug from './FixOSBug'
import { deepCopy } from '@/libs/tools'

export default {
  name: "SidebarItem",
  components: {
    Item
  },
  mixins: [FixOSBug],
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      onlyOneChild: null
    }
  },
  mounted () {
    this.$nextTick(() => {
    })
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      let flag = true
      if (!children) {
        children = []
      }
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          this.onlyOneChild = item
          return true
        }
      })
      if (showingChildren.length === 0) {
        this.onlyOneChild = {...parent, path: '', noShowingChildren: true}
        flag = true
      } else {
        if (showingChildren.length === 1 && parent.noShowingChildren) {
         this.onlyOneChild.name = parent.name
          parent.path = this.onlyOneChild.path
          flag = true
        } else {
          flag = false
        }
      }
      return flag
    },
    resolvePath (routePath, isFrame) {
      let url = isFrame ? this.basePath + routePath : routePath
      return url
    },
    iconName (item) {
      return item.meta && item.meta.icon || 'icon-caidan'
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/.ivu-menu-submenu-title-icon{
    right: 15px !important;
  }
</style>
