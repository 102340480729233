import draggable from './module/draggable'
import clipboard from './module/clipboard'
import hasPermi from './module/hasPermi'

const directives = {
  draggable,
  clipboard,
  hasPermi
}

export default directives
