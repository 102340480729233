export default {
  baseUrl: {
    // dev: 'https://www.cdhiway.com',
    // dev: 'https://10.168.1.249:5189',
    dev: 'https://dp.scmdxxny.com',
    // dev: 'https://10.168.1.248:5189',
    // dev: 'http://10.168.1.14:50000',
    // dev: 'https://sc.mdxxny.com',
    // pro: 'https://10.168.1.162:5189'
    pro: 'https://dp.scmdxxny.com',
    // pro: 'http://211.149.252.209:51896'
  },
  homeName: 'home',
  menu: {
    auth: ['authManage', 'logManage'],
    manager: ['admin'],
    authUser: ['YXJD001', 'SHGC001', 'YLGY001', 'LFGC001', 'YLNF001']
  },
  startPage: ['/big-screen','/appshow','/privacy','/service'],
  theme: '',
  sideTheme: 'theme-dark',
  sidebarLogo: true,
  title: '满电行新能源充电管理系统',
  menuText:'#bfcbd9',
  menuActiveText: '#409EFF',
  subMenuActiveText:'#f4f4f5',
  menuBg: '#01866D',
  menuHover:'#263445',
  sidebarTitle: '#ffffff',
  menuLightBg:'#ffffff',
  menuLightHover:'#f0f1f5',
  sidebarLightTitle: '#001529',
  subMenuBg: '#1f2d3d',
  subMenuHover:'#001528',
  sideBarWidth: '200px',
  whiteList: ['/login', '/appshow', '/privacy','/service', '/website'],
  company: '四川满电行新能源科技有限公司',
  companyUrl: 'https://www.mdxev.com',
  icpNum: '蜀ICP备2023005104号'
}
